// error routes
export default [
    {
        path: "",
        component: () => import('@/layouts/extranetLayout.vue'),
        children: [
            // { path: "acesso-antecipado",  name: "Acesso Antecipado",  component: () => import('@/views/landingPage/LandingView.vue')     },
            { path: "midias", name: "Mídias", component: () => import('@/views/MidiasView.vue') },
            { path: "feedback-compra", name: "Feedback Compras", component: () => import('@/views/FeedBackCompraView.vue') },
            { path: "challenge1", name: "Challenge1", component: () => import('@/views/challenges/Challenge1View.vue') },
            { path: "challenge2", name: "Challenge2", component: () => import('@/views/challenges/Challenge2View.vue') },
            { path: "challenge3", name: "Challenge3", component: () => import('@/views/challenges/Challenge3View.vue') },
            { path: "matricula", name: "Matricula", component: () => import('@/views/extranet/ValidarMatricula.vue') },
        ],
    },
]
